/*
 * @Author: chenl
 * @Date: 2023-12-01 10:44:43
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-04 10:52:47
 * @Description: ceshi
 */
export default [
  {
    url: '/1354/admin/get',
    type: 'get',
    response: () => {
      return {
        code: 0,
        message: '成功',
        data: {
          time: '2020-11-09 09:55:02'
        }
      }
    }
  }
]
