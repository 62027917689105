import http from "@/utils/http.js";
import qs from "qs";

//获取分组列表
const getGroupList = function (data){
     return http.request({url:'/tlb/productCategory/getProductGroupList',method:'post',data:qs.stringify(data)})
}
//获取分类列表
const getCategoryList = function (data){
    return http.request({url:'/tlb/productCategory/getProductCategoryList',method:'post',data:qs.stringify(data)})
}
//添加分类
const addCategory = function (data){
    return http.request({url:'/tlb/productCategory/addProductCategory',method:'post',data:qs.stringify(data)})
}
//添加分类 分组的下拉tab
const getSelectGroup = function (data){
    return http.request({url:'/tlb/productCategory/getProductGroupPullTab',method:'post',data:qs.stringify(data)})
}
//转移分类分组
const moveCategory = function (data){
    return http.request({url:'/tlb/productCategory/updateProductGroup',method:'post',data:qs.stringify(data)})
}
//删除分类
const delCategory = function (data){
    return http.request({url:'/tlb/productCategory/deleteProductCategory',method:'post',data:qs.stringify(data)})
}
//编辑 禁用 启用分类
const editCategory = function (data){
    return http.request({url:'/tlb/productCategory/updateProductCategory',method:'post',data:qs.stringify(data)})
}
//新增分组
const addProductGroup = function (data){
    return http.request({url:'/tlb/productCategory/addProductGroup',method:'post',data:qs.stringify(data)})
}

//查看关联商品
const getProductList = function (data){
    return http.request({url:'/tlb/productCategory/getProductCategoryDetailList',method:'post',data:qs.stringify(data)})
}
//重命名分组
const renameGroup = function (data){
    return http.request({url:'/tlb/productCategory/updateGroupName',method:'post',data:qs.stringify(data)})
}
//删除分组
const delGroup = function (data){
    return http.request({url:'/tlb/productCategory/deleteGroup',method:'post',data:qs.stringify(data)})
}

//后台分组分类选项框
const getGroupCategoryTree  = function (data){
    return http.request({url:'/tlb/productCategory/getGroupCategoryTree',method:'post',data:qs.stringify(data)})
}


export default {
    getGroupList,
    getCategoryList,
    addCategory,
    getSelectGroup,
    moveCategory,
    delCategory,
    editCategory,
    addProductGroup,
    getProductList,
    renameGroup,
    delGroup,
    getGroupCategoryTree 
}