<!--
 * @Author: chenl
 * @Date: 2023-12-01 10:01:27
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-04 11:36:32
 * @Description: 初始页
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
  @import '@/assets/css/config.scss';
  @import '@/assets/css/elementUpdate.scss';
  @import '@/assets/css/common.scss';
</style>
