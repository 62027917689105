/*
 * @Author: chenl
 * @Date: 2023-12-04 11:59:13
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-05 11:54:57
 * @Description: mock
 */
const mock = {
  get: '/1354/admin/get' // 获取数据
}

export default mock
