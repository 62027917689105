<template>
  <div class="basic-table">
    <div ref="tableHead">
      <slot name="tableHead"></slot>
    </div>
    <el-table
      v-loading="tableLoading"
      :data="data || tableData"
      :height="tableHeight"
      v-bind="tableProp"
      border
      size="mini"
      v-on="$listeners"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ padding: '0px' }"
      class="tableData"
    >
      <el-table-column
        v-for="item in columns"
        :key="item.dataIndex"
        :prop="item.dataIndex"
        :label="item.label"
        :width="item.width"
        :fixed="item.fixed"
        :align="
          (!item.tableColumnProp || !item.tableColumnProp.align) && 'center'
        "
        v-bind="item.tableColumnProp"
      >
        <template #header="{ column }">
          <div class="table-label">{{ column.label }}</div>
        </template>
        <template #default="{ row }" v-if="item.customSlot">
          <slot :name="item.customSlot" :row="row" :schema="item"></slot>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in actions"
        :key="index"
        :label="item.label"
        :width="item.width"
        fixed="right"
      >
        <template #header="{ column }">
          <div class="table-label">{{ column.label }}</div>
        </template>
        <template #default="{ row }">
          <div v-if="item.customSlot">
            <slot :name="item.customSlot" :row="row" :action="item"></slot>
          </div>
          <div v-else>
            <el-button
              type="text"
              v-for="(buttonItem, indexB) in item.buttons"
              :key="indexB"
              :style="{ color: buttonItem.color ? buttonItem.color : '' }"
              @click="buttonItem.onClick && buttonItem.onClick(row, buttonItem)"
              >{{ buttonItem.label }}</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div
      style="height: 32px; margin-top: 16px"
      ref="tableFooter"
      v-show="showPagination"
    >
      <slot name="tableFooter">
        <el-pagination
          style="float: right"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="paginationInfo.currentPage"
          :page-sizes="paginationInfo.pageSizes"
          :page-size="paginationInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paginationInfo.total"
        ></el-pagination>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    showPagination: {
      type: Boolean,
      default: true,
    },
    tableProp: {
      type: Object,
      default: () => ({}),
    },
    tableEvent: {
      type: Object,
      default: () => ({}),
    },
    tableSchema: {
      type: Array,
      default: () => [],
    },
    api: {
      type: [Function, Promise],
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    beforeFetch: {
      type: Function,
    },
    afterFetch: {
      type: Function,
    },
    rowInfo: {
      type: Object,
      default: () => ({
        rowKey: "rows",
      }),
    },
    immediateFetch: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    isWacthHeight: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: [],
      paginationInfo: {
        currentPage: 1,
        pageSizes: [10, 20, 40, 60],
        pageSize: 10,
        total: 0,
      },
      tableHeight: 450,
      tableData: [],
      tableLoading: false,
      resizeObserver: "",
    };
  },
  watch: {
    tableSchema: {
      deep: true,
      immediate: true,
      handler() {
        this.initSchema();
      },
    },
  },
  created() {
    if (this.immediateFetch) {
      this.fetch();
    }
  },
  destroyed() {
    this.resizeObserver.disconnect();
  },
  mounted() {
    // 判断是否做高度计算，默认true
    if (this.isWacthHeight) {
      this.$nextTick(() => {
        const basicSearchForm = document.querySelectorAll(".basic-search-form");
        // 只有当基础搜索框存在且只存在一个的时候才做表格的自适应高度计算
        if (basicSearchForm.length === 1) {
          this.tableHeight = "";
          this.resizeObserver = new ResizeObserver(() => {
            this.$nextTick(() => {
              this.tableHeight =
                document.body.getBoundingClientRect().height -
                this.$refs.tableHead.getBoundingClientRect().bottom -
                68 -
                64;
            });
          });
          this.resizeObserver.observe(basicSearchForm[0]);
        } else {
          this.$nextTick(() => {
            this.tableHeight =
              document.body.getBoundingClientRect().height -
              this.$refs.tableHead.getBoundingClientRect().top -
              68 -
              64;
          });
        }
      });
    } else {
      this.$nextTick(() => {
        this.tableHeight = "";
      });
    }
  },
  methods: {
    initSchema() {
      this.columns = this.tableSchema;
    },
    handleSizeChange(val) {
      if (this.paginationInfo.currentPage * val > this.paginationInfo.total) {
        this.paginationInfo.currentPage = 1;
      }
      this.$emit("pagination", {
        pageNum: this.paginationInfo.currentPage,
        pageSize: val,
      });
      this.paginationInfo.pageSize = val;
      if (this.api) {
        this.fetch();
      }
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", {
        pageNum: val,
        pageSize: this.paginationInfo.pageSize,
      });
      this.paginationInfo.currentPage = val;
      if (this.api) {
        this.fetch();
      }
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    fetch(val) {
      const params = (this.beforeFetch && this.beforeFetch(this.params)) || {
        ...val,
      };
      if (this.api) {
        this.tableLoading = true;
        this.api({
          pageIndex: this.paginationInfo.currentPage,
          pageSize: this.paginationInfo.pageSize,
          ...params,
        })
          .then((res) => {
            this.paginationInfo.total = res.records;
            this.tableData =
              (this.afterFetch && this.afterFetch(res[this.rowInfo.rowKey])) ||
              res[this.rowInfo.rowKey];
          })
          .finally(() => {
            this.tableLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-table {
  background-color: #fff;
  padding: 16px;
  ::v-deep .tableData .el-table__row td:not(:first-child) > :empty::before {
    content: "-";
  }
  ::v-deep th.el-table__cell {
    background-color: #f1f1f1;

    // .table-label {
    //   line-height: 20px;
    //   width: max-content;
    //   display: flex;
    //   align-items: center;

    //   &::before {
    //     display: block;
    //     content: '';
    //     height: 16px;
    //     padding-right: 8px;
    //     border-left: 1px solid rgba(81, 90, 110, 0.5);
    //   }
    // }
  }
}
</style>
