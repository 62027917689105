/*
 * @Author: chenl
 * @Date: 2023-12-01 10:44:43
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-05 10:46:04
 * @Description: 路由
 */
import Vue from "vue";
import Router from "vue-router";

// 路由重复点击报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router);

const constantRouterMap = [
  // 默认页
  {
    path: "/",
    redirect: 'login'
  },
  // 测试
  {
    path: "/ceshi",
    name: "ceshi",
    component: (resolve) => require(["@/views/ceshi"], resolve),
  },
  // 登录
  {
    path: "/login",
    name: "login",
    component: (resolve) => require(["@/views/login"], resolve),
  },
  // 首页
  {
    path: "/layout",
    name: "layout",
    component: (resolve) => require(["@/views/layout"], resolve),
    redirect: "/home",
    children: [
      // 首页
      {
        path: "/home",
        name: "home",
        meta: {
          name: '首页'
        },
        component: (resolve) => require(["@/views/home"], resolve),
      },
      // 砂石料场管理
      {
        path: "/stoneManage",
        name: "stoneManage",
        meta: {
          name: '站点管理'
        },
        component: (resolve) => require(["@/views/operateManage/stoneManage"], resolve),
      },
      // 新增/编辑砂石料场
      {
        path: "/updataSand",
        name: "updataSand",
        meta: {
          name: '站点管理'
        },
        component: (resolve) => require(["@/views/operateManage/stoneManage/updataSand"], resolve),
      },
      // 砂石料场管理详情
      {
        path: "/stoneManageDetails",
        name: "stoneManageDetails",
        meta: {
          name: '站点管理详情'
        },
        component: (resolve) => require(["@/views/operateManage/stoneManage/stoneManageDetails"], resolve),
      },
      // 商品分类管理
      {
        path: "/categoryManage",
        name: "categoryManage",
        meta: {
          name: '商品分类管理'
        },
        component: (resolve) => require(["@/views/operateManage/categoryManage"], resolve),
      },
      //供应商户管理
      {
        path: "/merchantManage",
        name: "merchantManage",
        meta: {
          name: '供应商户管理'
        },
        component: (resolve) => require(["@/views/operateManage/merchantManage/index"], resolve),
      },
      {
        path: "/merchantDetail",
        name: "merchantDetail",
        meta: {
          name: '供应商户管理详情'
        },
        component: (resolve) => require(["@/views/operateManage/merchantManage/merchantDetail"], resolve),
      },
        //料场商品管理
      {
        path: "/stockyardManage",
        name: "stockyardManage",
        meta:{
          name:'商品管理'
        },
        component: (resolve) => require(["@/views/operateManage/stockyardManage/index"], resolve),
      },
        //料场商品详情页
      {
        path: "/stockyardDetail",
        name: "stockyardDetail",
        meta:{
          name:'商品详情页'
        },
        component: (resolve) => require(["@/views/operateManage/stockyardManage/stockyardDetail"], resolve),
      },
        //料场新增，编辑商品页面
      {
        path: "/editStockyard",
        name: "editStockyard",
        meta:{
          name:'编辑商品页'
        },
        component: (resolve) => require(["@/views/operateManage/stockyardManage/editStockyard"], resolve),
      },
    ],
  },
  // 运营
  {
    path: "/operator",
    name: "operator",
    component: (resolve) => require(["@/views/layout"], resolve),
    redirect: "/operatorPay",
    children: [
      // 结算平台支付确认
      {
        path: "/operatorPayAffirm",
        name: "operatorPayAffirm",
        meta: {
          name: '结算平台支付确认'
        },
        component: (resolve) => require(["@/views/operator/payAffirm/index.vue"], resolve),
      },
      {
        path: "/payAffirmDetail",
        name: "payAffirmDetail",
        meta:{
          name:'商品详情页'
        },
        component: (resolve) => require(["@/views/operator/payAffirm/payAffirmDetail.vue"], resolve),
      },
    ],
  }
];



export default new Router({
  // mode: 'history', // 后端支持可开
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: constantRouterMap,
});
