/*
 * @Author: chenl
 * @Date: 2023-12-01 10:44:43
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-04 18:07:11
 * @Description: 砂石料场管理
 */
export default [
  //获取砂石场列表（分页）
  {
    url: "/tlb/stoneFactory/getFactoryList",
    type: "post",
    response: () => {
      return {
        result: 0,
        msg: "操作成功",
        rows: [
          {
            id: "5566",
            supplierId: "1122",
            factoryName: "天明砂石料场",
            factoryCode: "tm13543243",
            status: "1",
          },
        ],
      };
    },
  },
  // 新增砂石场
  {
    url: "/tlb/stoneFactory/addStoneFactory",
    type: "post",
    response: () => {
      return {
        result: 0,
        msg: "操作成功",
        data: "f80904d96f444780883d18340fc33685",
      };
    },
  },
  // 编辑砂石场
  {
    url: "/tlb/stoneFactory/updateStoneFactory",
    type: "post",
    response: () => {
      return {
        result: 0,
        msg: "操作成功",
        data: "f80904d96f444780883d18340fc33685",
      };
    },
  },
  // 查看砂石场详情
  {
    url: "/tlb/stoneFactory/getStoneFactoryById",
    type: "post",
    response: () => {
      return {
        result: 0,
        msg: "操作成功",
        data: "f80904d96f444780883d18340fc33685",
      };
    },
  },
];
