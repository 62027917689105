/*
 * @Author: chenl
 * @Date: 2023-12-05 11:18:17
 * @LastEditors: chenl
 * @LastEditTime: 2024-04-19 13:55:13
 * @Description: axios拦截器
 */

//下面这两个不一定需要引入，看你项目需要拦截的时候做什么操作，但是一般都需要引入store
import { contentType, requestTimeout, tokenName } from "@/config";
import axios from "axios"; //引入axios
import Vue from "vue";
// 总线层
import eventHub from "@/utils/eventBus";

let axiosIntercept = axios.create({
  baseURL: "/admin",
  headers: {
    "content-type": contentType,
  },
  timeout: requestTimeout, // request 超时
});

let num = 0;

// http request 拦截器
axiosIntercept.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers[tokenName] = token; //请求头加上token
    }
    num++;
    eventHub.emit("showloading");
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axiosIntercept.interceptors.response.use(
  (response) => {
    //拦截响应，做统一处理
    const code = response.data.code;
    if (code) {
      switch (code) {
        case 404:
          Vue.prototype.$message.error(response.data.msg);
          // router.push({ name: '404' })
          break
        default:
          if (code !== 200) {
            Vue.prototype.$message.error(response.data.msg);
          }
      }
    }
    num--;
    if (num <= 0) {
      eventHub.emit("closeLoading");
    } else {
      eventHub.emit("showloading");
    }
    if(response.data.result == -1) {
       Vue.prototype.$message.error(response.data.msg)
    }
    if(response.data.result == 99) {
      Vue.prototype.$message.error('当前token已过期')
      const url = process.env.NODE_ENV === 'production' ? 'https://holdpj.xmhold.com/holdproject/a/login' : 'https://pre.holdbuild.com/holdproject/a/login'
      window.location.href = url;
    }
    return response.data;
  },
  //接口错误状态处理，也就是说无响应时的处理
  () => {
    return Promise.reject("服务异常或找不到此接口"); // 返回接口返回的错误信息
  }
);

export default axiosIntercept;
