import http from '@/utils/http.js'
import qs from 'qs'

/*
* 供应商户管理
* */
//供应商户列表
const getMerchantList = function (data){
    return http.request({url:'/tlb/supplier/getPageList',data:qs.stringify(data),method:'post'})
}

// 获取供应商户管理列表（不分页）
const getList = function (data){
    return http.request({url:'/tlb/supplier/getList',data:qs.stringify(data),method:'post'})
}

// 新增供应商户
const addMerchant = function (data){
    return http.request({url:'/tlb/supplier/add',data:qs.stringify(data),method:'post'})
}

// 编辑供应商户
const editMerchant = function (data){
    return http.request({url:'/tlb/supplier/update',data:qs.stringify(data),method:'post'})
}

// 删除供应商户
const delMerchant = function (data){
    return http.request({url:'/tlb/supplier/deleteById/'+data.id,method:'post'})
}
//获取供应商户详情
const getMerchantDetail = function (data){
    return http.request({url:'/tlb/supplier/getById/'+data.id,method:'post'})
}





export default {
    getMerchantList,
    getList,
    addMerchant,
    editMerchant,
    delMerchant,
    getMerchantDetail,

}