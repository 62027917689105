/*
 * @Author: chenl
 * @Date: 2023-12-04 15:57:02
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-05 16:27:08
 * @Description: 全局配置
 */
import Vue from "vue";
// 引入api
import apis from "@/apis/index";
Vue.prototype.$apis = apis;
// 路由守卫
import "@/router/intercept.js";
// 引入mock
import "@/mock/index.js";
// axois拦截器
import http from "@/utils/http";
Vue.prototype.$http = http;
// 引入element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
// 自定指令
import "@/utils/instruct.js";
// 总线层
import eventHub from "@/utils/eventBus";

Vue.prototype.$eventHub = eventHub;
// 全局引入基础组件
import { BasicComponents } from "@/components/ABasic/index";
Vue.use(BasicComponents);
