/*
 * @Author: chenl
 * @Date: 2023-12-04 18:04:40
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-13 19:27:01
 * @Description: 状态管理
 */

import merchantApis from "@/apis/merchant.js";
import commonApis from "@/apis/common.js";
import categoryApis from "@/apis/category.js";
import stoneApis from "@/apis/stoneManage"

const state = {
  // 示例
  shili: "示例",
  // 菜单显示隐藏
  isCollapse: false,
  // 获取当前菜单位置
  menuPosition: "首页",
  // 获取所点击的路由信息
  menuTabs: [
    {
      route: "stoneManage",
      name: "站点管理",
    },
  ],
  // 获取归属商户
  attributableMerchant: [],
  factoryType: [],
  // 获取城市树结构
  treeCity: [],
  // 商品分类
  category: [],
  //获取归属砂石料场
  attributableStone: [],
};

// 类似于computed(计算属性，对现有的状态进行计算得到新的数据-------派生 )
const getters = {
  // 示例
  shili: (state) => state.shili,
  isCollapse: (state) => state.isCollapse,
  menuPosition: (state) => state.menuPosition,
  menuTabs: (state) => state.menuTabs,
  attributableMerchant: (state) => state.attributableMerchant,
  factoryType: (state) => state.factoryType,
  treeCity: (state) => state.treeCity,
  category: (state) => state.category,
  attributableStone: (state) => state.attributableStone,
};

// 发起异步请求
const actions = {
  updateShiliAsync({ commit }, value) {
    commit("updateShili", value);
  },
  async updateCategoryAsync({ commit }) {
    const data = await categoryApis.getGroupCategoryTree();
   (data.rows||[]).forEach((v) => {
      const list = [];
      v.categoryNameList.forEach((c, index) => {
        list.push({
          count: String(index),
          groupName: c,
        });
      });
      v.list = list
    });
    commit("updatecategory", data.rows);
  },
  async updateFactoryTypeAsync({ commit }) {
    const data = await stoneApis.getFactoryTypeList();
    data.rows.forEach((v) => {
      v.label = v.info;
      v.value = v.code;
    });
    commit("updateFactoryType", data.rows);
  },
  async updateAttributableMerchantAsync({ commit }) {
    const data = await merchantApis.getList();
    (data.rows||[]).forEach((v) => {
      v.label = v.supplierName;
      v.value = v.id;
    });
    commit("updateAttributableMerchant", data.rows);
  },
  async updateAttributableStoneAsync({ commit }) {
    const data = await stoneApis.getFactoryList();
    (data.rows||[]).forEach((v) => {
      v.label = v.factoryName;
      v.value = v.id;
    });
    commit("updateAttributableStone", data.rows);
  },
  async updateTreeCityAsync({ commit }) {
    const data = await commonApis.getCityList();
    const branchType1 = [];
    const branchType2 = [];
    const branchType3 = [];
    (data.rows||[]).forEach((v) => {
      if (v.branchType == "1") {
        branchType1.push(v);
      } else if (v.branchType == "2") {
        branchType2.push(v);
      } else if (v.branchType == "3") {
        branchType3.push(v);
      }
    });
    branchType2.forEach((a) => {
      a.children = [];
      branchType3.forEach((b) => {
        if (a.branchCode == b.parentId) {
          a.children.push(b);
        }
      });
      if(a.children.length == 0) {
        delete a.children
      }
    });
    branchType1.forEach((a) => {
      a.children = [];
      branchType2.forEach((b) => {
        if (a.branchCode == b.parentId) {
          a.children.push(b);
        }
      });
    });
    console.log('ceshi',branchType1)
    commit("updateTreeCity", branchType1);
  },
  updateisCollapseAsync({ commit }, value) {
    commit("updateisCollapse", value);
  },
  updateMenuPositionAsync({ commit }, value) {
    commit("updateMenuPosition", value);
  },
  updateMenuTabsAsync({ commit }, value) {
    commit("updateMenuTabs", value);
  },
};

// 使用它来修改数据(类似于methods)更改state中的数据必须使用mutations来进行更改
const mutations = {
  updateShili(state, value) {
    console.log(state, value);
  },
  updateAttributableMerchant(state, value) {
    state.attributableMerchant = value;
  },
  updateFactoryType(state, value) {
    state.factoryType = value;
  },
  updateAttributableStone(state, value) {
    state.attributableStone = value;
  },
  updateTreeCity(state, value) {
    console.log(11111, value);
    state.treeCity = value;
  },
  updateisCollapse(state, value) {
    state.isCollapse = value;
  },
  updateMenuPosition(state, value) {
    state.menuPosition = value;
  },
  updateMenuTabs(state, value) {
    state.menuTabs = value;
  },
  updatecategory(state, value) {
    state.category = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
