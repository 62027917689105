/*
 * @Author: chenl
 * @Date: 2023-12-01 10:44:43
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-04 10:52:47
 * @Description: 导出默认通用配置
 */
const setting = {
  //开发以及部署时的URL，hash模式时在不确定二级目录名称的情况下建议使用""代表相对路径或者"/二级目录/"，history模式默认使用"/"或者"/二级目录/"
  publicPath: '/',
  //生产环境构建文件的目录名
  outputDir: 'dist',
  //放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
  assetsDir: 'assets',
  //开发环境每次保存时是否输出为eslint编译警告
  lintOnSave: true,
  //进行编译的依赖
  transpileDependencies: ['resize-detector'],
  //默认的接口地址 如果是开发环境和生产环境走mock-server，当然你也可以选择自己配置成需要的接口地址
  baseURL: process.env.VUE_APP_API, //process.env.VUE_APP_URL, //'mock-server',
  //标题 （包括初次加载雪花屏的标题 页面的标题 浏览器的标题）
  title: '土拉宝运营平台',
  //简写
  abbreviation: '土拉宝运营平台',
  //开发环境端口号
  devPort: '8080',
  //版本号
  version: process.env.VUE_APP_VERSION,
  //路由模式，可选值为 history 或 hash
  routerMode: 'hash',
  //是否需要缓存
  isKeepAlive: false
}
module.exports = setting
