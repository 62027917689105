/*
 * @Author: chenl
 * @Date: 2024-03-06 14:53:32
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-11 16:13:54
 * @Description: 
 */
import http from "@/utils/http.js";

//上传接口
const upload = function (data) {
  return http.request({
    url: "/common/upload",
    data,
    method: "post",
    headers: {
      // 设置请求头
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

//上传接口
const getCityList = function (data) {
  return http.request({
    url: "/holdProject/getCityList",
    data,
    method: "post",
  });
};

//上传接口
const excelExport = function (data) {
  return http.request({
    url: "/sys/excelExport",
    data: data,
    responseType:"arraybuffer",
    method: "post",
  });
};

export default {
  upload,
  getCityList,
  excelExport
};
