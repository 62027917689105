<template>
  <div class="basic-search-form">
    <div class="title" v-if="isMataName">{{ $route.meta.name }}</div>
    <el-form
      :model="formModal"
      ref="vForm"
      v-on="$listeners"
      v-bind="$attrs"
      size="mini"
    >
      <el-row :gutter="16">
        <el-col
          v-for="item in showColumns"
          :key="item.prop"
          :span="item.colSpan || colSpan"
          :style="{ marginBottom: '16px' }"
        >
          <el-form-item :prop="item.prop" :label="item.label">
            <template v-if="item.type === 'input'">
              <el-input
                v-model="formModal[item.prop]"
                v-bind="item.componentProp"
                style="width: 100%"
              ></el-input>
            </template>
            <template v-if="item.type === 'select'">
              <el-select
                v-model="formModal[item.prop]"
                v-bind="item.componentProp"
                v-on="item.componentEvent"
                style="width: 100%"
              >
                <el-option
                  v-for="itemOption in item.options"
                  :key="itemOption.value"
                  :label="itemOption.label"
                  :value="itemOption.value"
                ></el-option>
              </el-select>
            </template>
            <template v-if="item.type === 'cascader'">
              <el-cascader
                v-model="formModal[item.prop]"
                v-bind="item.componentProp"
                v-on="item.componentEvent"
                style="width: 100%"
              >
              </el-cascader>
            </template>
            <template v-if="item.type === 'time-picker'">
              <el-time-select
                v-model="formModal[item.prop]"
                v-bind="item.componentProp"
                v-on="item.componentEvent"
                style="width: 100%"
              >
              </el-time-select>
            </template>
            <template v-if="item.type === 'date-picker'">
              <el-date-picker
                v-model="formModal[item.prop]"
                v-bind="item.componentProp"
                v-on="item.componentEvent"
                style="width: 100%"
              >
              </el-date-picker>
            </template>
            <template v-if="item.type === 'custom'">
              <slot :name="item.prop" :formdata="formModal"></slot>
            </template>
          </el-form-item>
        </el-col>
        <el-col
          :span="(4 - (showColumns.length % 4)) * colSpan"
          v-if="showColumns.length < 8"
        >
          <div
            style="margin-bottom: 16px"
            :style="{ float: showColumns.length === 4 ? 'right' : 'none' }"
          >
            <el-button
              size="mini"
              type="primary"
              @click="search"
              style="padding: 7px 22px"
              >查询</el-button
            >
            <el-button size="mini" style="padding: 7px 22px" @click="reset"
              >重置</el-button
            >
            <el-button
              size="mini"
              icon="el-icon-arrow-down"
              v-if="columns.length >= 8"
              @click="expand = !expand"
              >高级搜索</el-button
            >
          </div>
        </el-col>
        <el-col :span="(4 - (showColumns.length % 4)) * colSpan" v-else>
          <div style="float: right; margin-bottom: 16px">
            <el-button
              size="mini"
              type="primary"
              @click="search"
              style="padding: 7px 22px"
              >查询</el-button
            >
            <el-button size="mini" style="padding: 7px 22px" @click="reset"
              >重置</el-button
            >
            <el-button
              size="mini"
              @click="expand = !expand"
              icon="el-icon-arrow-up"
              >收起</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  另一种样式  -->
    <!--    <div class="el-divider el-divider&#45;&#45;horizontal" style="margin: 0">-->
    <!--      <div class="el-divider__text is-center" @click="expand = !expand">-->
    <!--        <i class="el-icon-arrow-up" v-if="expand"></i>-->
    <!--        <i class="el-icon-arrow-down" v-else></i>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    colSpan: {
      type: Number,
      default: 6,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    isMataName: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    columns: {
      deep: true,
      immediate: true,
      handler() {
        this.initModal();
      },
    },
  },
  created() {
    this.initModal();
  },
  computed: {
    showColumns() {
      if (!this.expand) {
        return this.columns.slice(0, 7);
      } else {
        return this.columns;
      }
    },
  },
  data() {
    return {
      expand: false,
      formModal: {},
    };
  },
  methods: {
    initModal() {
      this.columns.forEach((itemCol) => {
        this.$set(this.formModal, itemCol.prop, itemCol.defaultValue);
      });
    },
    search() {
      this.$emit("search", this.formModal);
    },
    reset() {
      this.$refs.vForm.resetFields();
      this.search();
      this.$emit('reset')
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-search-form {
  background-color: #fff;
  padding: 16px;
  .title {
    margin-bottom: 20px;
    font-size: 16px;
  }
  ::v-deep .el-form-item__content {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    flex-shrink: 0;
  }
  ::v-deep .el-form-item {
    margin: 0;
    display: flex;
  }
  .el-divider__text {
    font-size: 12px;
    cursor: pointer;
    padding: 0 20px;
    background-color: #dcdfe6;
  }
}
</style>
