import http from "@/utils/http.js";
import qs from "qs";

//获取料场商品列表
const getFactoryList = function (data) {
  return http.request({
    url: "/tlb/stoneFactory/getFactoryList",
    data: qs.stringify(data),
    method: "post",
  });
};

//新增砂石场
const addStoneFactory = function (data) {
  return http.request({
    url: "/tlb/stoneFactory/addStoneFactory",
    data: qs.stringify(data),
    method: "post",
  });
};

//查看砂石场详情
const getStoneFactoryById = function (data) {
  return http.request({
    url: "/tlb/stoneFactory/getStoneFactoryById",
    data: qs.stringify(data),
    method: "post",
  });
};

// 编辑砂石场
const updateStoneFactory = function (data) {
  return http.request({
    url: "/tlb/stoneFactory/updateStoneFactory",
    data: qs.stringify(data),
    method: "post",
  });
};

// 禁用/恢复砂石料场
const changeStoneFactoryStatusById = function (data) {
  return http.request({
    url: "/tlb/stoneFactory/changeStoneFactoryStatusById",
    data: qs.stringify(data),
    method: "post",
  });
};

// 删除砂石场
const delStoneFactoryById = function (data) {
  return http.request({
    url: "/tlb/stoneFactory/delStoneFactoryById",
    data: qs.stringify(data),
    method: "post",
  });
};

// 批量更新砂石场的供应商户
const batchUpdateFactorySupplier = function (data) {
  return http.request({
    url: "/tlb/stoneFactory/batchUpdateFactorySupplier",
    data: qs.stringify(data),
    method: "post",
  });
};

//获取站点类型列表
const getFactoryTypeList = function (data) {
  return http.request({
    url: "/tlb/stoneFactory/getFactoryTypeList",
    data: qs.stringify(data),
    method: "post",
  });
};

export default {
  getFactoryList,
  addStoneFactory,
  getStoneFactoryById,
  updateStoneFactory,
  changeStoneFactoryStatusById,
  delStoneFactoryById,
  batchUpdateFactorySupplier,
  getFactoryTypeList
};
