/*
 * @Author: chenl
 * @Date: 2023-12-01 10:44:43
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-04 10:53:47
 * @Description: 导出默认网路配置
 */
const network = {
  //配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
  formContentType: 'application/x-www-form-urlencoded',
  //最长请求时间
  requestTimeout: 10000,
  //token名称
  tokenName: 'Token',
  //token在localStorage、sessionStorage、cookie存储的key的名称
  tokenTableName: 'token'
}
module.exports = network
