/*
 * @Author: chenl
 * @Date: 2023-12-04 18:04:40
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-14 13:43:59
 * @Description: 状态管理
 */

const state = {
    // 示例
    shili: "示例2",
    loginUserId: ''
  };
  
  // 类似于computed(计算属性，对现有的状态进行计算得到新的数据-------派生 )
  const getters = {
    // 示例
    shili: (state) => state.shili,
    loginUserId: (state) => state.loginUserId,
  };
  
  // 发起异步请求
  const actions = {
    updateShiliAsync({ commit }, value) {
      commit("updateShili", value);
    },
    updateLoginUserIdAsync({ commit }, value) {
      commit("updateLoginUserId", value);
    },
  };
  
  // 使用它来修改数据(类似于methods)更改state中的数据必须使用mutations来进行更改
  const mutations = {
    updateShili(state, value) {
      console.log(state, value)
    },
    updateLoginUserId(state, value) {
      state.loginUserId = value;
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  