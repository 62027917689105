/*
 * @Author: chenl
 * @Date: 2023-12-05 16:17:07
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-05 16:17:26
 * @Description: 事件总线
 */
//导入mitt库
import mitt from 'mitt';
//创建事件触发器
const eventHub = mitt();
//导出事件触发器
export default  eventHub;