/*
 * @Author: chenl
 * @Date: 2023-12-04 17:12:05
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-04 17:12:21
 * @Description: 路由守卫
 */

import router from '@/router'

router.beforeEach((to, from, next) => {
  next()
})
