/*
 * @Author: chenl
 * @Date: 2023-12-01 10:44:43
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-05 12:00:26
 * @Description: mock
 */
import Mock from "mockjs";

import ceshi from "./ceshi";
import stoneManage from "./stoneManage";

const mocks = [...ceshi, ...stoneManage];

if (process.env.NODE_ENV === "mock") {
  for (const i of mocks) {
    Mock.mock(i.url, i.type || "get", i.response());
  }
}

export default mocks;
