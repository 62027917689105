/*
 * @Author: chenl
 * @Date: 2023-12-05 14:20:39
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-05 15:06:45
 * @Description: 自定义指令
 */
// 自定义指令防止按钮重复点击 v-preventReClick
import Vue from "vue";

// 防止重复点击 示例:v-preventReClick="2000" 默认1500
Vue.directive("preventReClick", {
  inserted: function (el, binding) {
    el.addEventListener("click", () => {
      if (el.style["pointer-events"] != "none") {
        el.style["pointer-events"] = "none";
        setTimeout(() => {
          el.style["pointer-events"] = "all";
        }, binding.value || 1500);
      }
    });
  },
});
