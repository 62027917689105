/*
 * @Author: chenl
 * @Date: 2023-12-01 10:44:43
 * @LastEditors: chenl
 * @LastEditTime: 2023-12-01 10:45:48
 * @Description: 3个子配置，通用配置|网络配置，建议在当前目录下修改config.js修改配置，会覆盖默认配置，也可以直接修改默认配置
 */
//默认配置
const setting = require('./default/setting.config')
const network = require('./default/net.config')
//自定义配置
const custom = require('./default/custom')
//导出配置（以自定义配置为主）
module.exports = Object.assign({}, setting, network, custom)
