/*
 * @Author: chenl
 * @Date: 2024-03-01 09:18:03
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-01 09:18:46
 * @Description: 
 */
import BasicSearchForm from '@/components/ABasic/SearchForm/index.vue';
import BasicTable from '@/components/ABasic/Table';

export const BasicComponents = {
  install(Vue) {
    Vue.component('BasicSearchForm', BasicSearchForm);
    Vue.component('BasicTable', BasicTable);
  },
};
