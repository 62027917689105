/*
 * @Author: chenl
 * @Date: 2023-12-04 11:59:13
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-06 17:26:24
 * @Description: api请求集合
 */
import mock from '@/apis/mock.js'



const apis = {
  ...mock
}

export default apis;
