/*
 * @Author: chenl
 * @Date: 2023-12-04 17:17:22
 * @LastEditors: chenl
 * @LastEditTime: 2024-03-01 14:38:58
 * @Description: 状态管理入口
 */
import Vue from "vue"; //首先引入vue
import Vuex from "vuex"; //引入vuex
import state from './module/state'
import user from './module/user'

import createPersistedstate from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // 模块
    state,
    user
  },
  plugins: [
    createPersistedstate({
      key: "tlb-operate-web", // 存数据的key名   自定义的  要有语义化
      storage: window.sessionStorage
      // paths: ['loginModule'] // 要把那些模块加入缓存,没加全局缓存
    }),
  ],
});
